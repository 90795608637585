// 大会报告专家列表
export const reporterList = [
    {
        imgSrc: 'https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2023/reporter/曹珂.jpg',
        name: '曹珂',
        unitName: '中国农业科学院郑州果树研究所',
        title: '桃变异组图谱构建与果实品质性状的分子育种'
    },
    {
        imgSrc: 'https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2023/reporter/陈亮.jpg',
        name: '陈亮',
        unitName: '中国农业科学院茶叶研究所',
        title: '基因组时代的茶树品质分子育种'
    },
    // {
    //     imgSrc: '',
    //     name: '陈绍江',
    //     unitName: '中国农业大学',
    //     title: '多作物通用单倍体技术创建与工程化'
    // },
    {
        imgSrc: "https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2023/reporter/邓兴旺.jpg",
        name: "邓兴旺",
        unitName: "北京大学现代农业研究院",
        title: "主要农作物分子设计育种"
    },
    {
        imgSrc: 'https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2023/reporter/梁承志.jpg',
        name: '梁承志',
        unitName: '中国科学院遗传与发育生物学研究所',
        title: '关于智能育种的几点思考'
    },
    {
        imgSrc: 'https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2023/reporter/马有志.jpg',
        name: '马有志',
        unitName: '中国农业科学院作物科学研究所',
        title: '转基因技术与育种应用'
    },
    {
        imgSrc: 'https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2023/reporter/王海洋.jpg',
        name: '王海洋',
        unitName: '华南农业大学',
        title: '耐密高产玉米分子设计育种'
    },
    // {
    //     imgSrc: '',
    //     name: '夏兰琴',
    //     unitName: '中国农业科学院作物科学研究所',
    //     title: '基因编辑原理与育种应用'
    // },
    {
        imgSrc: 'https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2023/reporter/徐云碧.jpg',
        name: '徐云碧',
        unitName: '中国农业科学院作物科学研究所、北京大学现代农业研究院',
        title: '植物设计育种：从株型到分子和智能'
    },
    {
        imgSrc: 'https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2023/reporter/谢传晓.jpg',
        name: '谢传晓',
        unitName: '中国农业科学院作物科学研究所',
        title: '作物基因编辑：从技术到目前和未来种业应用'
    },
    {
        imgSrc: "https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2023/reporter/张忠华.jpg",
        name: "张忠华",
        unitName: "青岛农业大学",
        title: "葫芦科作物果实发育调控元件的挖掘和功能分析"
    },
    {
        imgSrc: "https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2023/reporter/朱健康.jpg",
        name: "朱健康",
        unitName: "南方科技大学",
        title: "基因编辑育种发展趋势"
    },
]


// 大会组织委员会列表
export const committeeMemberList = [
    { name: "曹 珂", unitName: "中国农业科学院郑州果树研究所" },
    { name: "陈 亮", unitName: "中国农业科学院茶叶研究所" },
    { name: "陈庆山", unitName: "东北农业大学" },
    { name: "陈绍江", unitName: "中国农业大学" },
    { name: "邓兴旺", unitName: "北京大学现代农业研究院" },
    { name: "樊龙江", unitName: "浙江大学" },
    { name: "关义新", unitName: "中国科学院东北地理与农业生态研究所" },
    { name: "黄学辉", unitName: "上海师范大学" },
    { name: "金双侠", unitName: "华中农业大学" },
    { name: "梁承志", unitName: "中国科学院遗传与发育生物学研究所" },
    { name: "刘  欢", unitName: "华大万物科技有限公司" },
    { name: "卢孟柱", unitName: "中国林业科学院" },
    { name: "马有志", unitName: "中国农业科学院作物科学研究所" },
    { name: "钱  前", unitName: "崖州湾种子实验室" },
    { name: "沈志成", unitName: "浙江大学" },
    { name: "宋卫宁", unitName: "西北农林科技大学" },
    { name: "王道文", unitName: "河南农业大学" },
    { name: "王海洋", unitName: "华南农业大学" },
    { name: "王建康", unitName: "中国农业科学院作物科学研究所" },
    { name: "王克剑", unitName: "中国水稻研究所" },
    { name: "温常龙", unitName: "北京市农林科学院" },
    { name: "吴  俊", unitName: "南京农业大学" },
    { name: "徐云碧", unitName: "中国农业科学院作物科学研究所、北京大学现代农业研究院" },
    { name: "严建兵", unitName: "华中农业大学" },
    { name: "杨维才", unitName: "中国科学院遗传与发育生物学研究所" },
    { name: "叶志彪", unitName: "华中农业大学" },
    { name: "尹佟明", unitName: "南京林业大学" },
    { name: "张  健", unitName: "先正达集团中国" },
    { name: "张爱民", unitName: "河北农业大学" },
    { name: "张嘉楠", unitName: "石家庄博瑞迪生物技术有限公司" },
    { name: "张新友", unitName: "河南省农业科学院" },
    { name: "张兴平", unitName: "北京大学现代农业研究院" },
    { name: "张忠华", unitName: "青岛农业大学" },
    { name: "郑洪建", unitName: "上海市农业科学院、CIMMYT-中国特用玉米研究中心" },
    { name: "朱健康", unitName: "南方科技大学" },
]

// 展览企业列表
export const enterpriseList = [{
    href: "#",
    url: "https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/enterprise/brd192.png",
}, {
    href: "#",
    url: "https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/enterprise/houzeshengwu.png",
}, {
    href: "#",
    url: "https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/enterprise/%E7%80%9A%E8%BE%B0%E5%85%89%E7%BF%BCLOGO.png",
}, {
    href: "#",
    url: "https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/enterprise/biosearch.png",
}, {
    href: "#",
    url: "https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/enterprise/agilent.png",
}, {
    href: "#",
    url: "https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/enterprise/jingxing.png",
},
{
    href: "#",
    url: "https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/enterprise/huadazhizao.png"
},
{
    href: "#",
    url: "https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/enterprise/genemind.png"
},
{
    href: "#",
    url: "https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/enterprise/nikon.png"
},
{
    href: "#",
    url: "https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/enterprise/aidijinshengwu.png"
},
]



export const meeting_info = {
    organizer: ["北京大学现代农业研究院", "中国作物学会分子育种专业委员会"],
    undertakingUnit: ["石家庄博瑞迪生物技术有限公司"],
    coOrganizer: ["CIMMYT-中国特用玉米研究中心（上海市农业科学院）", '成都市邛崃天府现代种业园', '成都瀚辰光翼科技有限责任公司'],
    approval: ["成都瀚辰光翼科技有限责任公司",
        "深圳华大智造科技股份有限公司",
        "耐优生物技术（上海）有限公司",
        "武汉谷丰光电科技有限公司",
        "苏州中析生物信息有限公司",
        "南京衡乔仪器有限公司"],
    organizationalCommitteeOfConference: "待定"
}





