<template>
  <div class="organization">
    <div class="_title">组织机构</div>
    <div class="desc">
      <div class="wrap">
        <div class="corItem">
          <div class="corItem--l">主办单位：</div>
          <div class="corItem--r">
            <div>北京大学现代农业研究院</div>
            <div>中国作物学会分子育种专业委员会</div>
          </div>
        </div>

        <div class="corItem">
          <div class="corItem--l">承办单位：</div>
          <div class="corItem--r">
            <div>石家庄博瑞迪生物技术有限公司</div>
          </div>
        </div>
        <div class="corItem">
          <div class="corItem--l">协办单位：</div>
          <div class="corItem--r">
            <div>CIMMYT-中国特用玉米研究中心（上海市农业科学院）</div>
            <div>成都市邛崃天府现代种业园</div>
            <div>成都瀚辰光翼科技有限责任公司</div>
          </div>
        </div>
        <div class="corItem">
          <div class="corItem--l">赞助单位：</div>
          <div class="corItem--r">
            <div>成都瀚辰光翼科技有限责任公司</div>
            <div>深圳华大智造科技股份有限公司</div>
            <div>耐优生物技术（上海）有限公司</div>
            <div>武汉谷丰光电科技有限公司</div>
            <div>苏州中析生物信息有限公司</div>
            <div>南京衡乔仪器有限公司</div>
          </div>
        </div>
      </div>

      <div class="wrap">
        <p><span>大会组织委员会：</span></p>
        <p>
          <span>大会协调人: </span>徐云碧
          （北京大学现代农业研究院/中国农业科学院作物科学研究所）
        </p>
        <p>
          <span>组委会成员（按姓氏汉语拼音排序）：</span>
          <span v-for="(item, i) in list" :key="i" style="font-weight: 400">
            <br />
            <span
              class="name"
              style="font-weight: 400;display: inline-block;width: 90px;"
              >{{ item.name }}</span
            >{{ item.unitName }}
          </span>
        </p>
      </div>
      <div class="wrap"></div>
      <div class="wrap"></div>
    </div>
  </div>
</template>

<script>
import { committeeMemberList } from '../../data/d2023';
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.list = committeeMemberList;
  },
};
</script>

<style lang="less" scoped>
.organization {
  padding: 0 50px;
  padding-bottom: 150px;

  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  .desc-title {
    color: #337fe5;
    font-weight: bold;
    margin: 10px 0;
  }
  .wrap {
    margin-top: 50px;
  }
  p {
    margin-bottom: 4px !important;
    span {
      font-weight: bold;
    }
  }
}

.corItem {
  margin-bottom: 10px;
  display: flex;
  &--l {
    font-weight: bold;
  }
}
</style>
